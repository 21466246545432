<script lang="ts" setup>
import Title2 from "@/components/Title/Title2.vue"
import type { IHomeExhibition } from '@/api/page'
const props = defineProps({
  data: {
    type: Object as PropType<IHomeExhibition>,
    default: null,
  },
})
const storageUrl = import.meta.env.VITE_STORAGE_URL
</script>

<template>
  <div class="exhibition">
    <div class="wrap">
      <Title2 class="exhibition__title" title="EXHIBITION" />
      <figure class="exhibition__pic">
        <nuxt-link :to="data.url" :alt="data.title || 'EXHIBITION LINK'">
          <NuxtImg :src="`${storageUrl}/${data.image}`" :alt="data.title || 'EXHIBITION'">
          </NuxtImg>
        </nuxt-link>
      </figure>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exhibition {
  margin-bottom: 245px;

  @include max-media(767) {
    margin-bottom: 70px;
  }

  .wrap {
    max-width: 890px;
  }

  &__title {
    margin-bottom: 120px;
    text-align: center;

    @include max-media(767) {
      margin-bottom: 30px;
    }
  }

  &__pic {
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
